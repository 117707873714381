<template>
    <div class="othermenu-box mt-5">
        <div class="othermenu-btn">
            <div class="othermenu-ladies">

                <div class="menu-headline">
                    <h4>{{ otherMenu }}</h4>
                </div>

                <div id="tenteki" class="ladies-tenteki">
                    <a v-bind:href="links.ladiesTentekiLink" target="_blank" class="othermenu-detail">
                        <img v-bind:src="otherMenuList.ladiesTentekiImg" alt="" v-if="this.width > 900" />
                        <img v-bind:src="otherMenuList.ladiesTentekiImg" class="othermenu-img-sp" alt="" v-else />
                        <div class="position-absolute text-white ladies-tenteki-title">
                            <p>{{ otherMenuList.ladiesTentekiMenu }}</p>
                        </div>
                    </a>
                    <div class="text-start top-100 mt-3 mb-5">
                        <p class="description">{{ otherMenuList.ladiesTentekiDescription }}</p>
                    </div>
                </div>
                <div id="placenta" class="ladies-placenta">
                    <!-- <a v-bind:href="links.ladiesPlacentaLink" target="_blank" class="othermenu-detail"> -->
                    <a class="othermenu-detail">
                        <img v-bind:src="otherMenuList.ladiesPlacentaImg" alt="" v-if="this.width > 900" />
                        <img v-bind:src="otherMenuList.ladiesPlacentaImg" class="othermenu-img-sp" alt="" v-else />
                        <div class="position-absolute text-white ladies-placenta-title">
                            <p>{{ otherMenuList.ladiesPlacentaMenu }}</p>
                        </div>
                    </a>
                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ otherMenuList.ladiesPlacentaDescription }}</p>
                    </div>
                </div>
                <div id="diet" class="ladies-diet">
                    <a v-bind:href="links.ladiesDietLink" target="_blank" class="othermenu-detail">
                        <img v-bind:src="otherMenuList.ladiesDietImg" alt="" v-if="this.width > 900" />
                        <img v-bind:src="otherMenuList.ladiesDietImg" class="othermenu-img-sp" alt="" v-else />
                        <div class="position-absolute text-white ladies-diet-title">
                            <p>{{ otherMenuList.ladiesDietMenu }}</p>
                        </div>
                    </a>
                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ otherMenuList.ladiesDietDescription }}</p>
                    </div>
                </div>
            </div>
            <div class="othermenu-mens">
                <div class="mens-tenteki" style="">
                    <a v-bind:href="links.mensTentekiLink" target="_blank" class="othermenu-detail">
                        <img v-bind:src="otherMenuList.mensTentekiImg" alt="" v-if="this.width > 900" />
                        <img v-bind:src="otherMenuList.mensTentekiImg" class="othermenu-img-sp" alt="" v-else />

                        <div class="position-absolute text-white mens-tenteki-title">
                            <p>{{ otherMenuList.mensTentekiMenu }}</p>
                        </div>
                    </a>
                    <div class="text-start top-100 mt-3 mb-4">
                        <p class="description">{{ otherMenuList.mensTentekiDescription }}</p>
                    </div>
                </div>
                <div class="mens-placenta" style="">
                    <a v-bind:href="links.mensPlacentaLink" target="_blank" class="othermenu-detail">
                        <img v-bind:src="otherMenuList.mensPlacentaImg" alt="" v-if="this.width > 900" />
                        <img v-bind:src="otherMenuList.mensPlacentaImg" class="othermenu-img-sp" alt="" v-else />

                        <div class="position-absolute text-white mens-placenta-title">
                            <p>{{ otherMenuList.mensPlacentaMenu }}</p>
                        </div>
                    </a>
                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ otherMenuList.mensPlacentaDescription }}</p>
                    </div>
                </div>
                <div class="mens-diet" style="">
                    <a v-bind:href="links.mensDietLink" target="_blank" class="othermenu-detail">
                        <img v-bind:src="otherMenuList.mensDietImg" alt="" v-if="this.width > 900" />
                        <img v-bind:src="otherMenuList.mensDietImg" class="othermenu-img-sp" alt="" v-else />

                        <div class="position-absolute text-white mens-diet-title">
                            <p>{{ otherMenuList.mensDietMenu }}</p>
                        </div>
                    </a>
                    <div class="text-start top-100 mt-3">
                        <p class="description">{{ otherMenuList.mensDietDescription }}</p>
                    </div>
                </div>
            </div>

            <!-- <div class="position-absolute mens-aga" style="">
                <a v-bind:href="links.mensAgaLink" target="_blank">
                    <img v-bind:src="otherMenuList.mensAgaImg" alt="" v-if="this.width > 900" />
                    <img v-bind:src="otherMenuList.spMensAgaImg" alt="" v-else />
                    <div class="position-absolute text-white mens-aga-title">
                        <p>{{ otherMenuList.mensAgaMenu }}</p>
                    </div>
                </a>
                <div class="position-absolute text-start top-100 mt-3">
                    <p class="description">{{ otherMenuList.mensAgaDescription }}</p>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
    export default ({
    props: {
        'refmedia' : {
            type: String,
            default: ''
        }
    },
        data() {
            return {
                otherMenu: "その他",
                otherMenuList: {
                    ladiesTentekiMenu: "白玉点滴 (レディース)",
                    ladiesTentekiDescription: "高い美白効果が期待できるエミナルの「高濃度グルタチオン」。体の内側から全身美白へと導きます",
                    ladiesTentekiImg: require("@/assets/images/pc/menu_ladies_tenteki.png"),
                    spLadiesTentekiImg: require("@/assets/images/sp/menu_ladies_tenteki.png"),
                    mensTentekiMenu: "白玉点滴 (メンズ)",
                    mensTentekiDescription: "高い美白効果が期待できるメンズエミナルの「高濃度グルタチオン」。体の内側から全身美白へと導きます",
                    mensTentekiImg: require("@/assets/images/pc/menu_mens_tenteki.png"),
                    spMensTentekiImg: require("@/assets/images/sp/menu_mens_tenteki.png"),
                    ladiesPlacentaMenu: "プラセンタ注射 (レディース)",
                    ladiesPlacentaDescription: "美白効果・アンチエイジング・疲労回復などが期待できるエミナルのプラセンタ注射。",
                    ladiesPlacentaImg: require("@/assets/images/pc/menu_ladies_placenta_inactive.webp"),
                    spLadiesPlacentaImg: require("@/assets/images/sp/menu_ladies_placenta.png"),
                    mensPlacentaMenu: "プラセンタ注射 (メンズ)",
                    mensPlacentaDescription: "美白効果・アンチエイジング・疲労回復などが期待できるメンズエミナルのプラセンタ注射。",
                    mensPlacentaImg: require("@/assets/images/pc/menu_mens_placenta.png"),
                    spMensPlacentaImg: require("@/assets/images/sp/menu_mens_placenta.png"),
                    ladiesDietMenu: "医療ダイエット (レディース)",
                    ladiesDietDescription: "内服薬・食生活改善サポート、ボディハイフ施術により体脂肪を減らすエミナルの医療ダイエット。",
                    ladiesDietImg: require("@/assets/images/pc/menu_ladies_diet.png"),
                    spLadiesDietImg: require("@/assets/images/sp/menu_ladies_diet.png"),
                    mensDietMenu: "医療ダイエット (メンズ)",
                    mensDietDescription: "内服薬・食生活改善サポート、ボディハイフ施術により体脂肪を減らすメンズエミナルの医療ダイエット。",
                    mensDietImg: require("@/assets/images/pc/menu_mens_diet.png"),
                    spMensDietImg: require("@/assets/images/sp/menu_mens_diet.png"),

                },
                links: {
                    ladiesTentekiLink: "https://eminal-group.jp/tenteki/",
                    mensTentekiLink: "https://eminal-group.jp/tenteki_mens/",
                    ladiesPlacentaLink: "https://eminal-clinic.jp/menu/#anc_placenta",
                    mensPlacentaLink: "https://mens-eminal.jp/menu/#anc_06/",
                    ladiesDietLink: "https://eminal-clinic.jp/lp01/medicaldiet_04/",
                    mensDietLink: "https://mens-eminal.jp/lp/medicaldiet/",
                },
                bigFrameImg: require("@/assets/images/pc/bigframe.jpg"),
                spBigFrameImg: require("@/assets/images/sp/other_frame.jpg"),
                width: this.$store.state.width,
            }
    },
    created() {
        if (this.refmedia !== "") {
            this.links.ladiesTentekiLink += "?refmedia=" + this.refmedia
            this.links.mensTentekiLink += "?refmedia=" + this.refmedia
            this.links.mensAgaLink += "?refmedia=" + this.refmedia
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeWindow)
    },
    methods: {
        resizeWindow() {
            this.width = window.outerWidth
        }
    },
    })
</script>

<style scoped>
    .othermenu-ladies {
        width: 450px;
        display: block;
        border-top: 5px solid #E95A8E;
        padding: 3em 2em;
        background: linear-gradient(135deg, rgba(251,231,252,0.2) 0%, #FFF4FA 50%, rgba(255,229,244,0.1) 100%);
    }

    .othermenu-mens {
        width: 450px;
        border-top: 5px solid #707070;
        padding: 6.2em 2em 3em;
        background: linear-gradient(135deg, rgba(229,229,229,0.2) 0%, rgba(239,239,239,0.95) 50%, rgba(255,255,255,0.1) 100%);
    }

    .othermenu-btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .ladies-tenteki,
    .mens-tenteki {
        width:100%;
    }

    h4 {
        text-align: left;
        margin-bottom: 1em;
    }

    .ladies-tenteki-title,
    .mens-tenteki-title,
    .ladies-placenta-title,
    .mens-placenta-title,
    .ladies-diet-title,
    .mens-diet-title {
        top: 0;
        left: 0;
        right: 45%;
        font-size: 14px;
    }

    .mens-aga {
        top: 62%;
        left: 16%;
    }

    .mens-aga-title {
        top: 40%;
        left: 0;
        right: 45%;
    }

    .othermenu-detail {
        position: relative;
    }

    .description {
        margin-inline: 3%;
    }

    @media screen and (max-width: 900px) {
        .othermenu-ladies {
            width:100%;
        }

        .othermenu-mens {
            width:100%;
        }

        .othermenu-box {
            width: 100%;
        }
        
        .othermenu-btn {
            display: block;
        }

        .ladies-tenteki,
        .mens-tenteki {
            width: 100%;
        }
        
        .othermenu-img-sp {
            width: 80vw;
            height: 23vw;
        }

        .ladies-tenteki {
            top: 14%;
            left: 0;
            right: 0;
        }

        .mens-tenteki {
            right: 0;
        }

        .ladies-tenteki-title,
        .mens-tenteki-title,
        .ladies-placenta-title,
        .mens-placenta-title,
        .ladies-diet-title,
        .mens-diet-title {
            font-size: clamp(12px,2.6vw,20px);
            right:46%;
        }

        .mens-aga {
            top: 75%;
            right: 0;
            left: 0;
        }

        .mens-aga-title {
            top: 39%;
            right: 42%;
        }

        h4 {
            text-align: center;
        }

        .description {
            margin: 2% auto 5%;
            width: 80%;
            font-size: clamp(12px,2.1vw,16px);
        }
    }
</style>
